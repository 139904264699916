.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-header{
  /* padding: 20px; */
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 10vh;
  background-color: rgba(0, 0, 255, 0.5);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 8%);
}

.app-body-container{
  height: 90vh;
  width: 100%;
  display: flex;
}

.app-sidebar{
  height: 100%;
  width: 30vh;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding-top: 10px;
  transition: 0.2s;
}

.side-bar-tab{
  padding: 10px;
  cursor: pointer;
  font-family: 'cursive';
}

/* .side-bar-tab:hover{
  background-color: rgba(0, 0, 255, 0.1);
  transition: 0.5s;
} */

.active-tab{
  padding: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 255, 0.5);
  transition: 0.5s;
  font-family: 'cursive';
  color: #FFF;
}

.app-body {
  transition: 0.2s;
  width: 100%;
  transition: 0.2s;
  overflow-y: auto;
  /* padding-left: 10px */
}

.menu-container{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #d6d8dc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.app-name-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-name-container span{
  font-weight: 700;
  font-family: 'cursive';
  font-size: 20px;
}

.ant-popover-inner{
  padding: 5px !important;
  border-radius: 2px;
}

.logout-button{
  font-size: 15px;
  font-weight: 500;
  font-family: 'cursive';
  cursor: pointer;
}

.logout-button:hover{
  color: rgba(0, 0, 255, 0.5);
}

.app-module-container{
  width: 100%;
  height: 100%;
  /* background-color: #61dafb; */
}

.app-module-appbar{
  padding: 10px;
  width: 100%;
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 8%); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
}

.app-module-appbar-title h1{
  font-weight: 600;
  font-family: 'cursive';
  font-size: 18px;
}

.app-module-body{
  padding: 20px;
  height: 90%;
}

.sc-eDWCr {
  font-size: 15px;
  font-family: 'cursive';
}

.search-input-box span span{
  border-radius: 0px !important;
}

.search-input-box span span:hover{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.search-input-box span span:focus{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.search-input-box span span span{
  border-radius: 0px !important;
}

.search-input-box span span span:hover{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.search-input-box span span span:focus{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.search-input-box span span span button:hover{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.search-input-box span span span button:focus{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.search-input-box span span span{
  border-radius: 0px !important;
}

.search-input:focus{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.search-input:hover{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.customer-details-container{
  /* width: 100%; */
  background-color: #FFF;
  padding: 20px;
  margin-left: 20%;
  margin-right: 20%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 15%), 0px 2px 2px 0px rgb(0 0 0 / 15%), 0px 1px 5px 0px rgb(0 0 0 / 10%); */
}

/* .customer-details-container div:nth-child(1){
  display: grid;
  grid-template: 50px / auto auto;
  grid-gap: 10px;
} */

.ant-input{
  border-radius: 2px;
}

.ant-input:hover{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.ant-input:focus{
  border-color: rgba(0, 0, 255, 0.5) !important;
}

.customer-details-container h1{
  margin-bottom: 20px;
  font-size: 20px;
  font-family: 'cursive';
}

.customer-details-container-bottom{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.billing-container{
  padding: 1px;
  height: 100%;
  width: 100%;
  display: flex;
}

.billing-left-container{
  padding: 1px;
  min-height: 100%;
  width: 50%;
  margin: 1.5%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  min-height: 100%;
  /* min-width: 97%; */
}

.billing-right-container{
  height: 100%;
    padding: 1px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app-module-body-billing{
  padding: 10px;
  min-height: 90%;
}

.bill-preview{
  margin: 3%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  min-height: 100%;
  min-width: 97%;
}

.bill-base-container{
  /* padding: 10px; */
  border: 1.5px solid #2c204e;
}

.bill-header{
  padding: 10px;
  border-bottom: 1.5px solid #2c204e;
}

.bill-color{
  color: #2c204e;
}

.text-10{
  font-size: 10px;
  font-weight: 600;
  font-family: 'cursive';
}

.text-12{
  font-size: 12px;
  font-weight: 600;
  font-family: 'cursive';
}

.text-14{
  font-size: 14px;
  font-weight: 500;
  font-family: 'cursive';
}

.text-15{
  font-size: 15px;
  font-weight: 600;
  font-family: 'cursive';
}

.underline {
  text-decoration-line: underline;
}

.text-lg{
  font-size: 25px;
  font-weight: 600;
  font-family: 'cursive';
}

.text-italic{
  font-style: italic;
}

.text-normal{
  font-size: 12px;
  font-weight: 600;
  font-family: 'cursive';
  /* letter-spacing: -0.8px; */
}

.address-container{
  padding: 10px;
}

.text-warp{
  overflow-wrap: break-word;
}

.parts-container{
  /* padding: 1px; */
  min-height: 150px;
}

.table{
  border-top: 1px solid #2c204e;
  border-bottom: 1px solid #2c204e;
  width: 100%;
}

.header-row{
  border-bottom: 1px solid #2c204e;
}

.cell-one{
  width: 5%;
  text-align: center;
  font-family: 'cursive';
  border-right: 1px solid #2c204e;
  border-bottom: 1px solid #2c204e;
}

.cell-two{
  width: 40%;
  font-family: 'cursive';
  text-align: center;
  border-right: 1px solid #2c204e;
  border-bottom: 1px solid #2c204e;
}

.cell-three{
  width: 15%;
  font-family: 'cursive';
  text-align: center;
  border-bottom: 1px solid #2c204e;
}

.cell-one-body{
  width: 5%;
  text-align: center;
  font-family: 'cursive';
  padding: 10px;
  border-right: 1px solid #2c204e;
}

.cell-two-body{
  width: 40%;
  font-family: 'cursive';
  text-align: center;
  padding: 10px;
  border-right: 1px solid #2c204e;
}

.cell-three-body{
  width: 15%;
  font-family: 'cursive';
  text-align: center;
  padding: 10px;
}

.bottom-container{
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

.bottom-left-container{
  width: 50%;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #2c204e;
  height: 200px
}

.bottom-right-container{
  width: 50%;
  height: 200px
  /* padding: 10px; */
}

.bottom-left-container-labour{
  width: 50%;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #2c204e;
  /* height: 200px */
}

.bottom-right-container-labour{
  width: 50%;
  /* height: 200px */
  /* padding: 10px; */
}

.amount-label{
  width: 60%;
  text-align: right;
  padding: 3px;
  border-right: 1px solid #2c204e;
  border-bottom: 1px solid #2c204e;
}

.amount-value{
  width: 40%;
  text-align: center;
  padding: 3px;
  border-bottom: 1px solid #2c204e;
}

.amount-text-container{
  /* height: 70%; */
}

.bill-color{
  color: #2c204e;
}

.cell-extra{
  width: 10%;
  font-family: 'cursive';
  text-align: center;
  border-right: 1px solid #2c204e;
  border-bottom: 1px solid #2c204e;
  overflow-wrap: break-word;
}

.cell-extra-body{
  width: 10%;
  font-family: 'cursive';
  text-align: center;
  padding: 10px;
  border-right: 1px solid #2c204e;
}

.left-label{
  width: 66.9%
}

.right-label{
  width: 34.1%;
}

.table{
  width: 100%;
}

.table-cell-one{
  width: 20%;
  text-align: center;
  border-right: 1px solid #2c204e;
  border-bottom: 1px solid #2c204e;
}

.table-cell-two{
  width: 30%;
  border-right: 1px solid #2c204e;
  text-align: center;
  border-bottom: 1px solid #2c204e;
}

.table-cell-three{
  width: 30%;
  text-align: center;
  border-bottom: 1px solid #2c204e;
}

.table-cell-four{
  width: 20%;
  border-left: 1px solid #2c204e;
  text-align: center;
  border-bottom: 1px solid #2c204e;
}

.table-body-one{
  width: 20%;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
}
.table-body-two{
  width: 20%;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
}
.table-body-three{
  width: 10%;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
}
.table-body-four{
  width: 10%;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
}
.table-body-five{
  width: 20%;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
}
.table-body-six{
  width: 20%;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
}

.top-border{
  border-top: 1px solid #2c204e;
}

.date-bill-no-container{
  border: 1px solid #2c204e;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.estimation-text{
  color: #FFF;
  font-weight: 600;
  font-size: 15px;
  padding: 4px;
  background-color: #2c204e;
  border-radius: 3px;
}

.address-vehicle-no-container{
  border: 1px solid #2c204e;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-header-container{
  /* border: 1px solid #2c204e; */
  /* padding: 5px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fill-background-color{
  background-color: #2c204e;
}

.border{
  border: 1px solid #2c204e;
}

.total-cell{
  border-top: 1px solid #2c204e;
}

.custom-input-field{
  border: 1px solid black;
  padding: 5px;
  outline: none;
  margin: 5px;
  height: 40px;
}

.custom-input-field:focus{
  border: 1.5px solid black;
}

.custom-select-field div{
  border-radius: 0px !important;
  border-color: black;
  /* border: 1px solid#2c204e; */
}

.custom-select-field div:focus{
  border-color: black !important;
}

.custom-select-field div:hover{
  border-color: black !important;
}

.border-right{
  border-right: 1px solid #2c204e;
}

.border-top{
  border-top: 1px solid #2c204e;
}

.css-cpgvjg-MuiSnackbar-root{
  bottom: 24px !important;
  left: auto !important;
  right: 10px !important;
}

.piece-class{
  color: green;
  margin-left: 10px;
  font-weight: 600;
}

.liter-class{
  color: red;
  margin-left: 10px;
  font-weight: 600;
}

.set-class{
  color: orange;
  margin-left: 10px;
  font-weight: 600;
}

