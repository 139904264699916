.login-base-container{
    height: 100vh;
    width: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    overflow-x: auto;
}

.login-box-container{
    padding: 20px;
    height: 400px auto;
    width: 350px;
    background-color: rgba(0, 0, 255, 0.2);
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.login-button{
    background-color: rgba(0, 0, 255, 0.4) !important;
}

.login-text{
    font-size: x-large;
    font-weight: 600;
    font-family:'cursive'
}

.register-text{
    margin-top: 10px;
    cursor: pointer;
    color: rgba(0, 0, 255, 0.4);
}